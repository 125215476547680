var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.progressBarType === 'circle')?_c('CircularBar',_vm._b({on:{"reset":function($event){return _vm.$emit('reset')}}},'CircularBar',{ form: _vm.form, page: _vm.page, userData: _vm.userData, component: _vm.component },false)):(_vm.progressBarType === 'simple')?_c('FormProgress',_vm._b({on:{"reset":function($event){return _vm.$emit('reset')}}},'FormProgress',{
    pages: _vm.visiblePages,
    userData: _vm.userData,
    showPercentage: _vm.showPercentage,
    form: _vm.form,
    component: _vm.component,
    currentPageIndex: _vm.currentVisiblePageIndex,
  },false)):_c('div',{staticClass:"ProgressBar",class:_vm._elSel('BarContainer').join(' ')},[_vm._l((_vm.visiblePages),function(page,i){return [_c('div',{key:page.id,class:[
        _vm._elSel('BarNode'),
        {
          current: _vm.currentPageId === page.id,
          completed: _vm.currentPageIndex > i,
          navigate: _vm.component.allow_nav,
          fill: _vm.component.fill_connectors,
        } ],on:{"click":function($event){$event.stopPropagation();return _vm.goToPage(page.id)}}},[(page.label_icon || _vm.component.node_icon)?_c('Icon',{class:_vm._elSel('BarNodeIcon'),attrs:{"icon":page.label_icon || _vm.component.node_icon}}):_vm._e(),_c('div',{class:_vm._elSel('BarNodeText')},[_vm._v(" "+_vm._s(page.label || page.key)+" ")])],1),(i < _vm.pages.length - 1)?_c('div',{key:((page.id) + "-connector"),class:[
        _vm._elSel('BarNodeConnector'),
        { completed: _vm.currentPageIndex - 1 > i, fill: _vm.component.fill_connectors } ]},[_c('div',{class:[
          _vm._elSel('BarNodeConnectorInner'),
          { completed: _vm.currentPageIndex - 1 > i, fill: _vm.component.fill_connectors } ]})]):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }