<template>
  <!-- v-if=!component.use_custom_bar -->
  <CircularBar
    v-if="progressBarType === 'circle'"
    v-bind="{ form, page, userData, component }"
    @reset="$emit('reset')"
  />
  <FormProgress
    v-else-if="progressBarType === 'simple'"
    v-bind="{
      pages: visiblePages,
      userData,
      showPercentage,
      form,
      component,
      currentPageIndex: currentVisiblePageIndex,
    }"
    @reset="$emit('reset')"
  />
  <div v-else class="ProgressBar" :class="_elSel('BarContainer').join(' ')">
    <template v-for="(page, i) in visiblePages">
      <div
        :class="[
          _elSel('BarNode'),
          {
            current: currentPageId === page.id,
            completed: currentPageIndex > i,
            navigate: component.allow_nav,
            fill: component.fill_connectors,
          },
        ]"
        :key="page.id"
        @click.stop="goToPage(page.id)"
      >
        <Icon
          v-if="page.label_icon || component.node_icon"
          :icon="page.label_icon || component.node_icon"
          :class="_elSel('BarNodeIcon')"
        />
        <div :class="_elSel('BarNodeText')">
          {{ page.label || page.key }}
        </div>
      </div>
      <div
        v-if="i < pages.length - 1"
        :class="[
          _elSel('BarNodeConnector'),
          { completed: currentPageIndex - 1 > i, fill: component.fill_connectors },
        ]"
        :key="`${page.id}-connector`"
      >
        <div
          :class="[
            _elSel('BarNodeConnectorInner'),
            { completed: currentPageIndex - 1 > i, fill: component.fill_connectors },
          ]"
        >
          &nbsp;
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  components: {
    CircularBar: () => import('./CircularFormProgress'),
    FormProgress: () => import('./FormProgress.vue'),
  },
  inject: {
    _elSel: { default: () => () => [] },
    _getFlowPages: { default: () => () => [] },
    _goToPage: { default: () => () => {} },
  },
  props: {
    form: Object,
    page: Object,
    component: Object,
    userData: Object,
  },
  data() {
    return {}
  },
  computed: {
    showPercentage() {
      const componentVal = this.component && this.component.showProgressPercentage
      if (componentVal === undefined) {
        const formVal = this.form && this.form.showProgressPercentage
        return formVal === undefined ? false : formVal
      }
      return componentVal
    },
    progressBarType() {
      if (!this.component.progress_type && this.component.use_custom_bar) return 'custom'
      return this.component.progress_type || 'circle'
    },
    currentPageId() {
      return this.userData.currentPageId
    },
    highestPageId() {
      return this.userData.highest_page_reached_id
    },
    currentPageIndex() {
      const index = this.pages.findIndex(p => p.id === this.currentPageId)
      return Math.max(index, 0)
    },
    currentVisiblePageIndex() {
      const index = this.visiblePages.findIndex(p => p.id === this.currentPageId)
      return Math.max(index, 0)
    },
    visiblePages() {
      return this._getFlowPages(true)
    },
    pages() {
      return this._getFlowPages()
    },
  },
  watch: {},
  methods: {
    goToPage(id) {
      if (!this.component.allow_nav) return
      const targetPageIndex = this.pages.findIndex(p => p.id === id)
      const highestPageIndex = this.pages.findIndex(p => p.id === this.highestPageId)
      if (targetPageIndex <= highestPageIndex) this._goToPage(id)
    },
  },
}
</script>
